export const API_URL = {
  GET_USERS: "/users",
  GET_STATS: "/stats",
  GET_ALL_CLOSETTE: "/admin/closette",
  GET_PRODUCTS: "/products",

  // Authentication
  CLIENT_LOGIN: "auth/client/login",

  // clients
  CLIENTS: "/clients",
  CLIENT_ONBOARD: "/clients/onboard",
  CLIENT_VERIFY: "/clients/onboard/verification",
  CLIENT_GENERATE_CODE: "/clients/generate/code",
  CLIENT_UPDATE_STATUS: "/clients/update/status",
  CLIENT_RESET_PASSWORD: "/clients/reset-password",
  CLIENT_VERIFY_ADMIN: "/clients/verify",
  CLIENTS_STATS: "/clients/statistics",

  // usrs
  USERS: "/users",
  USERS_LIST: "/users/admin",
  USERS_UPDATE_STATUS: "/users/update/status",
  USER_VERIFY_ADMIN: "/users/verify",
  USERS_STATS: "/users/stats",

  // students
  STUDENTS_LIST: "/students",
  SURVEYS: "/survey",
  SURVEYS_DELETE: "/survey/remove",
  SURVEYS_UPLOAD: "/survey/upload",
  SURVEYS_STD_RESPONSES: "/survey/responses",
  SURVEYS_STD_RESULTS: "/survey/responses/results",
};
