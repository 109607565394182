import React from "react";

export const Section = ({ children, className, ...props }) => {
  return (
    <>
      <section className={`mb-3 ${className}`} {...props}>
        {children}
      </section>
    </>
  );
};

export const SectionHeader = ({ title }) => {
  return (
    <h6 className="text-uppercase text-muted bg-light border py-3 text-center mb-3">
      {title}
    </h6>
  );
};
